import { RegraDeNegocio, TipoDeRegraDePermissao } from '@/models/RegraDeNegocio';
import { TipoDeOperacao, TipoDeRecurso } from '@/models/Recurso';
import { Recurso } from '@/models/Recurso';
import { Perfil } from '@/models/Perfil';
import { ItemDeMenu } from './itensDeMenu';

const recursos: Recurso[] = [
	{
		nome: 'Manifesto de Transporte',
		chave: 'manifestos-transporte',
	},
	{
		nome: 'Usuários',
		chave: 'usuarios',
	},
	{
		nome: 'Produtos',
		chave: 'produtos',
	},
	{
		nome: 'Vendas',
		chave: 'vendas',
	},
	{
		nome: 'Pré-vendas',
		chave: 'pre-vendas',
	},
	{
		nome: 'Lojas',
		chave: 'lojas',
	},
	{
		nome: 'Fiscal',
		chave: 'regras-imposto',
	},
	{
		nome: 'Estoque',
		chave: 'depositos',
	},
	{
		nome: 'Tabela de Preços',
		chave: 'tabela-de-precos',
	},
	{
		nome: 'Clientes',
		chave: 'clientes',
	},
	{
		nome: 'Minha Conta',
		chave: 'contas',
	},
	{
		nome: 'Perfis',
		chave: 'perfis',
	},
	{
		nome: 'Pedidos',
		chave: 'pedidos',
	},
	{
		nome: 'Logs de Pedidos',
		chave: 'logs-de-pedidos',
	},
	{
		nome: 'Plugins',
		chave: 'plugins',
	},
	{
		nome: 'Marcas',
		chave: 'marcas',
	},
	{
		nome: 'Atributos',
		chave: 'atributos',
	},
	{
		nome: 'Tags',
		chave: 'tags',
	},
	{
		nome: 'Alteração em Massa',
		chave: 'alteracao-em-massa',
	},
	{
		nome: 'Fechamentos',
		chave: 'fechamentos-de-caixa',
	},
	{
		nome: 'Cupons',
		chave: 'cupons',
	},
	{
		nome: 'Dashboard de Produtos',
		chave: 'situacao-produtos',
	},
	{
		nome: 'Planos',
		chave: 'planos',
	},
	{
		nome: 'Tipos de Movimentação',
		chave: 'motivos-da-movimentacao',
	},
	{
		nome: 'Categorias',
		chave: 'categoria',
	},
	{
		nome: 'Documentos Fiscais',
		chave: 'documentos-fiscais',
	},
	{
		nome: 'Fornecedores',
		chave: 'fornecedores',
	},
	{
		nome: 'Contas a Pagar',
		chave: 'contas-a-pagar',
	},
	{
		nome: 'Contas a Receber',
		chave: 'contas-a-receber',
	},
	{
		nome: 'Etiquetas',
		chave: 'etiquetas',
	},
	{
		nome: 'Dashboard',
		chave: 'dashboards',
	},
	{
		nome: 'Analytics Vendas',
		chave: 'analytics-vendas',
	},
	{
		nome: 'Analytics Estoque',
		chave: 'analytics-estoque',
	},
	{
		nome: 'Analytics Financeiro',
		chave: 'analytics-financeiro',
	},
	{
		nome: 'Analytics Clientes',
		chave: 'analytics-clientes',
	},
	{
		nome: 'Relatórios',
		chave: 'relatorios',
	},
	{
		nome: 'Processos',
		chave: 'importacoes',
	},
	{
		nome: 'Painel',
		chave: 'sangria',
	},
	{
		nome: 'Grid de Relatórios',
		chave: 'relatorios-em-grid',
	},
	{
		nome: 'Tela De Metas',
		chave: 'tela-de-metas',
	},
	{
		nome: 'Excluir Item Composto',
		chave: 'excluir-item-composto',
	},
	{
		nome: 'Romaneios',
		chave: 'pedidos-romaneio',
	},

	{
		nome: 'Faq',
		chave: 'tela-de-faq',
	},
	{
		nome: 'Cashback',
		chave: 'cashback',
	},
	{
		nome: 'Grupos Econômicos',
		chave: 'grupos-economicos',
	},
	{
		nome: 'Notas em trânsito',
		chave: 'notas-em-transito',
	},
	{
		nome: 'Emissão De Notas',
		chave: 'emissao-notas',
	},
	{
		nome: 'Listagem De Notas',
		chave: 'listagem-de-notas',
	},
	{
		nome: 'Custos',
		chave: 'custos',
	},
	{
		nome: 'Campanha de Cashback',
		chave: 'campanha-de-cashback',
	},
	{
		nome: 'Motivos de Cancelamento',
		chave: 'motivos-de-cancelamento',
	},
	{
		nome: 'DNA do Produto',
		chave: 'dna-do-produto',
	},
	{
		nome: 'Adquirente',
		chave: 'adquirente',
	},
	{
		nome: 'Modalidades de Venda',
		chave: 'modalidades-de-venda',
	},
	{
		nome: 'Tela de aniversariantes',
		chave: 'tela-de-aniversariantes',
	},
	{
		nome: 'Estação',
		chave: 'estacao',
	},
	{
		nome: 'Motivos de Cancelamento Fiscal',
		chave: 'motivos-de-cancelamento-fiscal',
	},
	{
		nome: 'Alteração em Massa de Clientes',
		chave: 'alteracao-em-massa-de-clientes',
	},
	{	nome: 'Ação de Cashback', 
		chave: 'acao-de-cashback',
	},
]

export const listaDeRecursos: Recurso[] = [...recursos].sort((recursoA, recursoB) => recursoA.nome.localeCompare(recursoB.nome))

export const listaDeRegrasDeNegocio: RegraDeNegocio[] = [
	{
		nome: 'Acesso Irrestrito',
		chave: 'acesso-irrestrito',
		dica: 'Sem limitação para acessar vendas e lojas',
	},
	{
		nome: 'Ocultar Valor das Vendas',
		chave: 'ocultar-valor-das-vendas',
		dica: 'Restrição para visualizar valor das vendas na listagem',
	},
	{
		nome: 'É Motorista',
		chave: 'motorista',
		dica: 'Usuários com este perfil são listados nas seleções de motorista',
	},
	{
		nome: 'Vendedor',
		chave: 'é-vendedor',
	},
	{
		nome: 'Pode Inativar Funcionários',
		chave: 'pode-inativar-funcionario',
		dica: 'Pode inativar o login dos usuários no sistema',
	},
	{
		nome: 'Recebe notificação ao atingir estoque mínimo',
		chave: 'altera-estoque-minimo',
		dica: 'Permite receber alerta de estoque',
	},
	{
		nome: 'Pode editar produtos integrados',
		chave: 'pode-editar-produto-integrado',
		dica: 'Permite o usuário editar produtos integrados',
	},
	{
		nome: 'Exibir vendas no cadastro do cliente',
		chave: 'exibir-vendas-no-cadastro-do-cliente',
		dica: 'Permite ao usuário acessar a aba de vendas no cadastro do cliente',
	},
	{
		nome: 'Pode criar voucher',
		chave: 'pode_criar_voucher',
		dica: 'Permite criar voucher na edição de cliente',
	},
	{
		nome: 'Imprimir os preços conforme a regra de negócio do caixa',
		chave: 'imprimir-precos-das-etiquetas-conforme-regra-do-caixa',
		dica: 'Ao imprimir a etiqueta, o preço do produto será aplicado de acordo com a regra de negócio do caixa',
	},
	{
		nome: 'Exibir o tipo de cliente na tela de vendas',
		chave: 'exibir-tipo-de-cliente-tela-de-vendas',
	},
	{
		nome: 'Exibir somente as vendas do PDV aberto',
		chave: 'exibir-somente-vendas-do-pdv-ativo',
		dica: 'Se não houver PDV aberto no caixa, a tela de vendas não será exibida',
	},
	{
		nome: 'Recebe notificação de sangria ',
		chave: 'pode-receber-notificacao-de-sangria',
	},
	{
		nome: 'Pode visualizar bloqueios de cliente',
		chave: 'pode-visualizar-bloqueios-de-cliente',
	},
	{
		nome: 'Pode visualizar aba de crediário dos clientes',
		chave: 'pode-visualizar-crediario-clientes',
	},
]

export const operacoesDeCaixa: RegraDeNegocio[] = [
	{
		nome: 'Pode Cancelar Notas Fiscais',
		chave: 'pode-cancelar-nota-fiscal',
	},
	{
		nome: 'Pode Fazer Devolução de Itens',
		chave: 'pode-fazer-devolucao',
	},
	{
		nome: 'Pode Excluir Itens da Venda',
		chave: 'pode-excluir-item-de-venda',
	},
	{
		nome: 'Pode Alterar Plano do Cliente',
		chave: 'pode-alterar-planos-do-cliente',
	},
	{
		nome: 'Pode Ler Voucher do Cliente',
		chave: 'pode-ler-voucher-do-cliente',
	},
	{
		nome: 'Reabrir venda',
		chave: 'reabrir-venda',
	},
	{
		nome: 'Informar Vendedor',
		chave: 'informar-vendedor',
	},
	{
		nome: 'Permite Modificar Cadastro de Nome e Cpf Cliente',
		chave: 'cadastro-nome-cpf-cliente',
	},
	{
		nome: 'Informar Quantidade De Produtos',
		chave: 'informar-quantidade-de-produtos',
	},
	{
		nome: 'Pode Alterar Prazo De Devolução',
		chave: 'pode-alterar-prazo-de-devolucao',
	},
	{
		nome: 'Pode Alterar quantidade de produto',
		chave: 'pode-alterar-quantidade-de-produto',
	},
	{
		nome: 'Pode alterar preço do produto',
		chave: 'pode-alterar-preco-do-produto',
	},
	{
		nome: 'Permite fazer vendas acima do valor limite do funcionário',
		chave: 'permite-vendas-acima-do-valor-limite-do-funcionario',
	},
	{
		nome: 'Pode Conceder Brinde',
		chave: 'pode-fazer-venda-brinde',
	},
	{
		nome: 'Pode Vender Consignado',
		chave: 'pode-fazer-venda-consignada',
	},
	{
		nome: 'Pode Vender Demonstração',
		chave: 'pode-fazer-venda-demonstracao',
	},
	{
		nome: 'Pode alterar valor do troco ao abrir o caixa',
		chave: 'pode-alterar-troco-no-caixa',
		dica: 'Habilitar esta opção permite que o usuário altere o valor do troco ao abrir o caixa.',
	},
	{
		nome: 'Função de auto-logoff ao fechar o caixa',
		chave: 'auto-logoff-ao-fechar-caixa',
		dica: 'Ao fechar o caixa, a função de auto-logoff garante que a sessão do usuário seja encerrada automaticamente',
	},
	{
		nome: 'Função de auto-login ao abrir o caixa',
		chave: 'auto-login-ao-abrir-caixa',
		dica: 'Ao abrir o caixa, a função de auto-login garante que a sessão do usuário seja iniciada automaticamente',
	},
	{
		nome: 'Pode Conceder Desconto',
		chave: 'pode-conceder-desconto',
	},
	{
		nome: 'Pode liberar produto sem estoque',
		chave: 'pode-liberar-produto-sem-estoque',
		dica: 'No caixa, ao bipar produto e ele não tiver estoque você poderá liberar o produto',
	},
	{
		nome: 'Pode alterar bloqueios de cliente',
		chave: 'pode-alterar-bloqueios-de-cliente',
	},
]

export const operacaoDePedido: RegraDeNegocio[] = [
	{
		nome: 'Pode Faturar Pedidos',
		chave: 'pode-faturar-pedidos',
	},
	{
		nome: 'Pode Cancelar Pedidos',
		chave: 'pode-cancelar-pedidos',
	},
	{
		nome: 'Pode Modificar Status',
		chave: 'pode-modificar-status',
		dica: 'Modifica o status exceto para Faturar e Cancelar',
	},
	{
		nome: 'Pode devolver pedido',
		chave: 'pode-devolver-pedido',
		dica: 'Permite devolução de pedidos na tela de pedidos',
	},
]

export const operacoesDeVenda: RegraDeNegocio[] = [
	{
		nome: 'Pode Alterar Vendedor',
		chave: 'pode-alterar-vendedor',
	},
	{
		nome: 'Pode Enviar Notas Por E-mail',
		chave: 'pode-enviar-notas-por-email',
	},
	{
		nome: 'Pode receber notificação ao criar venda integrada',
		chave: 'pode-receber-notificacao',
		dica: 'Ao criar venda integrada após coclusão irá gerar uma notificação avisando que a venda foi concluída',
	},
	{
		nome: 'Pode cadastrar bandeira de cartão',
		chave: 'pode-cadastrar-bandeiras',
	},
	{
		nome: 'Pode Cancelar Vendas',
		chave: 'pode-cancelar-venda',
	},
]

export const operacoesDaTelaDeDnaDoProduto: RegraDeNegocio[] = [
	{
		nome: 'Exibir estoque da loja na tela de DNA do produto',
		chave: 'exibir-estoque-na-tela-de-consulta-de-preço',
		dica: 'Ao consultar o produto, será exibido o estoque da loja pesquisada, assim como o estoque de todas as outras lojas vinculadas a ela',
	},
	{
		nome: 'Exibir o preço do produto somente da tabela de preço que não está associada a nenhum tipo de cliente',
		chave: 'exibir-preco-somente-da-tabela-sem-tipo-de-cliente',
		dica: 'Ao consultar o produto com a opção selecionada, o sistema exibirá somente o preço da tabela não associada a um tipo de cliente. Caso essa opção não esteja selecionada, serão exibidas todas as tabelas vinculadas à loja',
	},
]

export const integracao: RegraDeNegocio[] = [
	{
		nome: 'Perfil de integração',
		chave: 'perfil-de-integração',
		dica: 'Perfil que será destinado somente a integração',
	},
	{
		nome: 'Perfil de Multi-Marcas',
		chave: 'perfil-de-multi-marcas',
		dica: 'Perfil que será destinado somente a Multi-Marcas',
	},
]


export const gruposDeRegras = [
	{
		nome: 'Operações Gerais',
		regras: listaDeRegrasDeNegocio,
	},
	{
		nome: 'Operações de Caixa',
		regras: operacoesDeCaixa,
	},
	{
		nome: 'Operações de Venda',
		regras: operacoesDeVenda,
	},
	{
		nome: 'Operações de Pedidos',
		regras: operacaoDePedido,
	},
	{
		nome: 'Operações da tela de DNA do produto',
		regras: operacoesDaTelaDeDnaDoProduto,
	},
	{
		nome: 'Integracao',
		regras: integracao,
	},
]

export const listaDeOperacoes: TipoDeOperacao[] = [
	'ler',
	'alterar',
	'criar',
	'deletar',
]

export function temPermissaoRecurso(permissoes: string[], recurso: TipoDeRecurso, operacao: TipoDeOperacao) {
	const permissao = montarOperacaoDeRecurso(operacao, recurso)
	return permissoes.includes(permissao)
}

export function temPermissaoItemDeMenu(permissoes: string[], item: ItemDeMenu) {
	return !item.recurso || temPermissaoRecurso(permissoes, item.recurso, item.operacao || 'ler')
}

export function montarOperacaoDeRecurso(operacao: TipoDeOperacao, recurso: TipoDeRecurso) {
	return `${operacao}/${recurso}`
}

export function perfilTemRegraDeNegocio(perfil: Perfil | null, regra: TipoDeRegraDePermissao) {
	return perfil?.permissoes.find(permissao => permissao === regra) || false
}

export function perfilTemAutorizacao(perfil: Perfil | null) {
	return (requerimento?: string | string[] | null) => {
		if (!requerimento || !requerimento.length) return true
		return (
			perfil?.permissoes.find(permissao =>
				typeof requerimento === 'string'
					? permissao === requerimento
					: requerimento.includes(permissao),
			)
		)
	}
}
